import { Image } from "antd";
import { Col, Row } from "antd";
import { svh100, useIsWideScreen } from "../hooks/useIsWideScreen";
import { IOView } from "../types";
import { CSSProperties, ReactNode } from "react";

export const LargeSpinner: IOView<{
  text?: string | ReactNode;
  height?: number | string;
  width?: number | string;
  style?: CSSProperties;
}> = ({ text, width, height, style }) => {
  const isWideScreen = useIsWideScreen();
  return (
    <Row
      justify={"center"}
      align={"middle"}
      style={{
        height: height ?? `calc(${svh100} - 40px)`,
        width: width ?? "100%",
        ...(style || {}),
      }}
    >
      <Col
        style={{
          textAlign: "center",
          width: "100%",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          gap: text ? 75 : 0,
        }}
      >
        <Image
          alt="waitSpin"
          preview={false}
          src="/wait-spin.gif"
          style={{
            maxHeight: 80,
            maxWidth: 80,
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: isWideScreen ? "38px" : "27px",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {text}
        </div>
      </Col>
    </Row>
  );
};
